<template>
  <div class="career">
    <!-- HERO -->
    <div class="career__hero">
      <div class="container">
        <div class="career__info">
          <div class="career__logo" :class="{ 'is-kz': $i18n.locale === 'kz' }">
            {{ $t("growTogether") }}
          </div>
          <h1 class="career__title">
            {{ $t("buildCareer") }}
          </h1>
          <div class="career__subtitle">
            {{ $t("leaveRequest") }}
          </div>
        </div>

        <a class="career__btn" @click="scrollToVacancy">
          {{ $t("allVacancies") }}
        </a>

        <!-- <div class="career__images">
          <img
            src="@/assets/img/magnum-career/long.png"
            alt="career image"
            class="career__img-long"
          />
          <div class="career__images-wrapper">
            <img
              src="@/assets/img/magnum-career/top.png"
              alt="career image"
              class="career__img-top"
            />
            <img
              src="@/assets/img/magnum-career/bottom.png"
              alt="career image"
              class="career__img-bottom"
            />
          </div>
        </div> -->

      </div>
    </div>

    <!-- COME -->
    <div class="career-come">
      <div class="container">
        <div class="career-come__info">
          <h2 class="career-come__title big-title">
            {{ $t("createRetail") }}
          </h2>
          <p class="career-come__descr">
            {{ $t("WeCreateStores") }}<br /><br />
            {{ $t("internationalTeam") }}
          </p>
        </div>

        <div class="career-come__img">
          <img
            src="@/assets/img/magnum-career/come.png"
            alt="career come image"
          />
        </div>
      </div>
    </div>

    <!-- WARRANTY -->
    <!-- <div class="career-warranty">
      <div class="container">
        <div class="career-warranty__info">
          <h2 class="career-warranty__title big-title">
            {{ $t("we") }}
            <span> {{ $t("guarantee") }}</span>
            {{ $t("toYou") }}
          </h2>
          <ul class="career-warranty__items">
            <li class="career-warranty__item">
              {{ $t("stableSalary") }}
            </li>
            <li class="career-warranty__item">
              {{ $t("freeMeals") }}
            </li>
            <li class="career-warranty__item">
              {{ $t("paidInternship") }}
            </li>
            <li class="career-warranty__item">
              {{ $t("freeHealthBook") }}
            </li>
            <li class="career-warranty__item">
              {{ $t("travelPayment") }}
            </li>
          </ul>
        </div>

        <div class="career-warranty__img">
          <img
            src="@/assets/img/magnum-career/warranty.png"
            alt="warranty image"
          />
        </div>
      </div>
    </div> -->

    <!-- ADVANTAGES -->
    <div class="career-advantages">
      <div class="container">
        <h2
          class="career-advantages__title"
          v-html="$t('vacancyAdvantages')"
        ></h2>
        <div class="career-advantages__content">
          <ul class="career-advantages__items">
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_2") }}
            </li>
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_3") }}
            </li>
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_4") }}
            </li>
          </ul>
          <ul class="career-advantages__items">
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_5") }}
            </li>
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_6") }}
            </li>
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_7") }}
            </li>
          </ul>
        </div>
        <a href="#vacancy" class="career-advantages__btn">
          {{ $t("allVacancies") }}
        </a>
      </div>
    </div>

    <!-- CHOICE -->
    <div class="about__numbers career-numbers">
      <div class="container">
        <h2 class="about__numbers-title">
          <span> {{ $t("why") }}</span>
          {{ $t("chooseMagnum") }}
        </h2>

        <div class="about__slider career-slider">
          <swiper ref="swiperRef" class="mySwiper" :options="swiperOption">
            <swiper-slide>
              <div class="about__slide">
                <div class="about__slide-title">№1</div>
                <div class="about__slide-descr">
                  {{ $t("retailNetwork") }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="about__slide">
                <div class="about__slide-title">
                  {{ $t("branches") }}
                </div>
                <div class="about__slide-descr">
                  {{ $t("openCities") }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="about__slide">
                <div class="about__slide-title">
                  {{ $t("employeePromotionsNumber") }}
                </div>
                <div class="about__slide-descr">
                  {{ $t("employeePromotions") }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="about__slide">
                <div class="about__slide-title">> 13 500</div>
                <div class="about__slide-descr">
                  {{ $t("manConstantly") }}
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="about__slide">
                <div class="about__slide-title">
                  {{ $t("employeeTrainedNumber") }}
                </div>
                <div class="about__slide-descr">
                  {{ $t("employeeTrained") }}
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>

        <div class="about__slider-nav career-slider-nav">
          <button class="career-slider-prev" @click="prevSlide">
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="31" cy="31" r="30.5" fill="white" stroke="#B7B7B7" />
              <path
                d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
                fill="#B5B5B5"
              />
            </svg>
          </button>
          <button class="career-slider-next" @click="nextSlide">
            <svg
              width="62"
              height="62"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="31" cy="31" r="30.5" fill="white" stroke="#B7B7B7" />
              <path
                d="M49 30C49.5523 30 50 30.4477 50 31C50 31.5523 49.5523 32 49 32V30ZM14 31L24 25.2265V36.7735L14 31ZM49 32L23 32V30L49 30V32Z"
                fill="#B5B5B5"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <vacancy-block id="vacancy" />
    <!-- CAREER -->
  </div>
</template>

<script>
export default {
  name: "MagnumCareer",
  components: {
    VacancyBlock: () => import("@/components/vacancy/VacancyBlock.vue"),
  },
  data() {
    return {
      swiperOption: {
        slideTo: 1,
        spaceBetween: 20,
        loop: true,
        // responsive: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          400: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs?.swiperRef;
    },
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext();
    },
    scrollToVacancy() {
      document.querySelector("#vacancy").scrollIntoView();
    },
  },
};
</script>

<style scoped>
.about__slide {
  margin-right: 0;
}
.about__slide-title {
  font-size: 22px;
}
.about__slide-descr {
  max-width: none;
  font-size: 18px;
}
</style>
